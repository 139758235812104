import { useStore } from './store'
import { NoticationOptions, actions } from './actions'

const notifications = {
  requestError: 'Error during request',
  addedMovie: 'Movie added!',
}

const useAddNotification = () => {
  const [, dispatch] = useStore()

  return (key: keyof typeof notifications, options: NoticationOptions) => {
    dispatch(actions.addNotification(notifications[key], options))
  }
}

export default useAddNotification
