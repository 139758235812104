import { useEffect } from 'react'
import { useStore as useAppStore, actions as appStoreActions } from './'

const Init = () => {
  const [, dispatch] = useAppStore()
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.hash.substring(1))
    const search = urlParams.get('search')
    if (search) {
      dispatch(appStoreActions.updateSearchQuery(search))
    }
    const view = urlParams.get('view')
    if (view) {
      dispatch(appStoreActions.changeView(view))
    }
    const registerToken = urlParams.get('t')
    if (registerToken) {
      dispatch(appStoreActions.setRegisterToken(registerToken))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default Init
