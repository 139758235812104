import { useEffect } from 'react'
import { io } from 'socket.io-client'
import { useQueryClient } from '@tanstack/react-query'

import { apiUrl } from '../../config'
import { Movie, Torrent, TorrentMap } from '../../interfaces'

interface TorrentWithMovieId extends Torrent {
  movieId: number
}

const SocketIoService = () => {
  const queryClient = useQueryClient()
  useEffect(() => {
    const socket = io(apiUrl, {
      withCredentials: true,
    })

    const newMovie = (newMovie: Movie) => {
      const oldMovieList = queryClient.getQueryData<Movie[]>(['movies']) || []
      const newMovieList = [newMovie, ...oldMovieList]
      queryClient.setQueryData(['movies'], newMovieList)
    }

    const updateTorrents = (updatedTorrents: TorrentWithMovieId[]) => {
      const oldMovieList = queryClient.getQueryData<Movie[]>(['movies']) || []
      const oldTorrentList =
        queryClient.getQueryData<TorrentMap>(['torrents']) || {}

      const newMovieList = [...oldMovieList]
      const updatedTorrentsList = { ...oldTorrentList }
      updatedTorrents.forEach((torrent) => {
        if (torrent.movieId) {
          // connect torrent in case it was new
          const movie = oldMovieList.find(
            (movie) => movie.id === torrent.movieId,
          )
          if (movie) {
            movie.torrentId = torrent.id
          }
        }
        updatedTorrentsList[torrent.id] = torrent
      })

      queryClient.setQueryData(['torrents'], updatedTorrentsList)
      queryClient.setQueryData(['movies'], newMovieList)
    }

    socket.on('movie.create', newMovie)
    socket.on('torrents.update', updateTorrents)

    return () => {
      socket.off('movie.create', newMovie)
      socket.off('torrents.update', updateTorrents)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
export default SocketIoService
