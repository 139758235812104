import { apiUrl } from '../config'

interface ApiClientOptions {
  data?: any
  expectedStatusCode?: number
  includeCredentials?: boolean
  authorization?: string
}

export interface ApiClientError extends Error {
  response: any
  statusCode: number
}

const baseRequest =
  (method: 'GET' | 'POST' | 'PUT' | 'PATCH') =>
  async <T = any>(path: string, options: ApiClientOptions = {}): Promise<T> => {
    const { data, expectedStatusCode, includeCredentials, authorization } =
      options

    let search = ''
    if (method === 'GET' && data) {
      search = `?${new URLSearchParams(data)}`
    }

    let body: any
    if ((method === 'POST' || method === 'PUT' || method === 'PATCH') && data) {
      body = JSON.stringify(data)
    }

    const requestHeaders: HeadersInit = new Headers()
    requestHeaders.set('Content-Type', 'application/json')
    if (authorization) {
      requestHeaders.set('authorization', authorization)
    }

    const url = `${apiUrl}${path}${search}`
    const response = await fetch(url, {
      method,
      body,
      headers: requestHeaders,
      credentials: includeCredentials ? 'include' : 'omit',
    }).then((r) => r.json())

    if (response.statusCode !== (expectedStatusCode || 200)) {
      const err = new Error() as ApiClientError
      err.statusCode = response.statusCode
      if (response.message) {
        err.message = response.message
        throw err
      }
      if (response.error) {
        err.message = response.error
        throw err
      }
      err.response = response
      err.message = 'Unknown error'
      throw err
    }
    return response.result
  }

const apiClient = {
  get: baseRequest('GET'),
  post: baseRequest('POST'),
  put: baseRequest('PUT'),
  patch: baseRequest('PATCH'),
}

export default apiClient
