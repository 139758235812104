import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider } from '@mui/material/styles'

import App from './App'
import theme from './theme'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { CssBaseline } from '@mui/material'

import QueryProvider from './providers/QueryProvider'
import AuthProvider from './providers/AuthProvider'
import SocketIOConnector from './services/SocketIO'

import {
  StoreProvider as NotificationsProvider,
  NotificationDisplayer,
} from './store/notifications'
import {
  StoreProvider as AppStoreProvider,
  Init as InitAppStore,
} from './store/app'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <StrictMode>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <QueryProvider withDevtools>
        <AppStoreProvider>
          <InitAppStore />
          <NotificationsProvider>
            <NotificationDisplayer />
            <AuthProvider>
              <SocketIOConnector />
              <App />
            </AuthProvider>
          </NotificationsProvider>
        </AppStoreProvider>
      </QueryProvider>
    </ThemeProvider>
  </StrictMode>,
)
