import { unstable_createMuiStrictModeTheme } from '@mui/material/styles'

const theme = unstable_createMuiStrictModeTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#731251',
    },
    secondary: {
      main: '#4a0b63',
    },
    text: {
      primary: '#142426',
    },
  },
})

export default theme
