import type { FC } from 'react'
import { styled } from '@mui/material/styles'

import { Button as MuiButton, CircularProgress } from '@mui/material'

const ProgressInButton = styled(CircularProgress)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
})

const StyledDiv = styled('div')({
  position: 'relative',
})

type MuiButtonProps = Parameters<typeof MuiButton>[0]
interface Props extends MuiButtonProps {
  loading?: boolean
}

const ButtonContainer: FC<Props> = ({ loading, fullWidth, ...props }) => {
  if (loading) {
    return (
      <StyledDiv style={{ display: fullWidth ? 'initial' : 'inline-block' }}>
        <MuiButton fullWidth={fullWidth} {...props} />{' '}
        <ProgressInButton size={24} />{' '}
      </StyledDiv>
    )
  }
  return <MuiButton {...props} />
}

export default ButtonContainer
