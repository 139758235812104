import { Suspense, useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'

import { Box, Fade, Paper } from '@mui/material'
import getRandomMovieStill from './queries/getRandomMovieStill'
import LoadingIndicator from './components/LoadingIndicator'

const getImageFromUrl = async (url: string) => {
  try {
    const response = await fetch(url)

    return response.blob()
  } catch (err) {
    console.error(err)
    return null
  }
}

const Background = () => {
  const [movieName, setMovieName] = useState<string | null>(null)
  const [backgroundImage, setBackgroundImage] = useState<string | null>(null)
  const [newBackgroundImage, setNewBackgroundImage] = useState<string | null>(
    null,
  )
  const { data } = useQuery(['getMovieStill'], getRandomMovieStill, {
    staleTime: 1 * 60 * 1000, // 1 minute
    refetchInterval: 1 * 60 * 1000, // 1 minute
  })

  const backgroundTransitionTimeout = 1000
  useEffect(() => {
    const timeouts: NodeJS.Timeout[] = []
    const getNewBackgroundImage = async (
      movieInfo: NonNullable<typeof data>,
    ) => {
      const { src, movie } = movieInfo
      const image = await getImageFromUrl(src)

      if (!image) {
        // failed to download new image, so lets keep the old one
        return
      }
      const background = `url(${URL.createObjectURL(image)})`
      setNewBackgroundImage(background)
      setMovieName(null)

      timeouts.push(
        setTimeout(() => {
          setMovieName(movie)
        }, backgroundTransitionTimeout / 2),
      )

      timeouts.push(
        setTimeout(() => {
          setBackgroundImage(background)
        }, backgroundTransitionTimeout),
      )

      // done in seperate timeout to prevent flicker from the changing
      // of background
      timeouts.push(
        setTimeout(() => {
          setNewBackgroundImage(null)
        }, backgroundTransitionTimeout + 100),
      )
    }

    if (data) {
      getNewBackgroundImage(data)
    }

    return () => {
      timeouts.forEach((timeout) => clearTimeout(timeout))
    }
  }, [data])

  const bgStyle = backgroundImage ? { backgroundImage } : {}
  const newBgStyle = newBackgroundImage
    ? { backgroundImage: newBackgroundImage }
    : {}

  return (
    <>
      <Box
        sx={{
          width: '100%',
          minHeight: '100%',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'absolute',
          zIndex: -1001,
        }}
        style={bgStyle}
      />
      <Fade
        in={Boolean(newBackgroundImage)}
        timeout={backgroundTransitionTimeout}
      >
        <Box
          sx={{
            width: '100%',
            minHeight: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'absolute',
            zIndex: -1000,
          }}
          style={newBgStyle}
        />
      </Fade>
      {movieName && (
        <Box
          sx={{
            display: { xs: 'none', md: 'block' },
            color: '#fff',
            position: 'absolute',
            bottom: 0,
            right: 0,
            textShadow: '1px 1px 1px #333',
          }}
        >
          {movieName}
        </Box>
      )}
    </>
  )
}

const Layout = ({
  children,
  stretch,
}: {
  children: React.ReactNode
  stretch?: boolean
}) => {
  const stretchProps = stretch ? { width: '100%', height: '100%' } : {}

  return (
    <Box
      sx={{
        width: '100vw',
        minHeight: '100vh',
        position: 'relative',
      }}
    >
      <Background />
      <Box
        sx={{
          height: stretch ? '100vh' : '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          px: [2, 4, 8],
          py: [4, 6, 8],
        }}
      >
        <Paper
          elevation={4}
          sx={{ maxWidth: 1000, p: [1, 2], ...stretchProps }}
        >
          <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>
        </Paper>
      </Box>
    </Box>
  )
}

export default Layout
