import { lazy } from 'react'
import Layout from './Layout'

import { StateType, useStore as useAppStore } from './store/app'

const MovieListScreen = lazy(() => import('./screens/MovieListScreen'))
const SearchResultScreen = lazy(() => import('./screens/SearchResultScreen'))

const renderScreen = (state: StateType) => {
  switch (state.screen) {
    case 'IMDB_SEARCH':
      return <SearchResultScreen state={state} />
    case 'MOVIELIST':
      return <MovieListScreen />
    default:
      return <div>Unknown screen {state.screen}</div>
  }
}

const App = () => {
  const [state] = useAppStore()

  return <Layout stretch>{renderScreen(state)}</Layout>
}

export default App
