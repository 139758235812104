import { styled } from '@mui/material/styles'
import { CircularProgress } from '@mui/material'

const LoadingWrapper = styled('div')({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

const LoadingIndicator = () => (
  <LoadingWrapper data-testid="loadingIndicator">
    <CircularProgress />
  </LoadingWrapper>
)

export default LoadingIndicator
