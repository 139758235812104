import { produce } from 'immer'

import { types } from './actions'
import { StateType } from './store'

/* eslint-disable no-param-reassign */
const reducer = produce((draft: StateType, action) => {
  switch (action.type) {
    case types.UPDATE_SEARCH_QUERY: {
      const newQuery = action.payload.newQuery

      const urlParams = new URLSearchParams(window.location.hash.substring(1))
      urlParams.set('search', newQuery)
      window.location.hash = urlParams.toString()

      draft.searchQuery = newQuery
      break
    }
    case types.CHANGE_VIEW: {
      const newView = action.payload.newView.trim()

      const urlParams = new URLSearchParams(window.location.hash.substring(1))
      urlParams.set('view', newView)
      window.location.hash = urlParams.toString()

      draft.screen = newView
      break
    }
    case types.SET_REGISTER_TOKEN: {
      const newToken = action.payload.newToken

      if (!newToken) {
        const urlParams = new URLSearchParams(window.location.hash.substring(1))
        urlParams.delete('t')
        window.location.hash = urlParams.toString()
      }

      draft.registerToken = newToken ? newToken.trim() : null
      break
    }
    default: {
      break
    }
  }
})

export default reducer
