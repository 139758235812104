import { lazy } from 'react'
import { useStore as useAppStore } from '../../store/app'

const Login = lazy(() => import('./Login'))
const Register = lazy(() => import('./Register'))

const LoginSwitcher = () => {
  const [{ registerToken }] = useAppStore()

  if (registerToken) {
    return <Register />
  }
  return <Login />
}

export default LoginSwitcher
