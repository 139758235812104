import { FC, createContext, useContext, useReducer, ReactNode } from 'react'

import initialState from './initial'
import reducer from './reducer'

type ContextType = [state: typeof initialState, dispatch: React.Dispatch<any>]

const StateContext = createContext<ContextType>([initialState, () => null])

type Props = {
  children?: ReactNode
}

export const StoreProvider: FC<Props> = ({ children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
)

export const useStore = () => useContext(StateContext)
