import { ReactNode, isValidElement, useState } from 'react'

import { useQuery } from '@tanstack/react-query'

import getSessionInfo from '../../queries/getSessionInfo'
import { useAddNotification } from '../../store/notifications'

import LoadingIndicator from '../../components/LoadingIndicator'
import LoginScreen from '../../screens/LoginScreen'

import type { ApiClientError } from '../../queries/apiClient'
import Layout from '../../Layout'
import { Box, Stack, Typography } from '@mui/material'
import Button from '../../components/Button'

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const addNotification = useAddNotification()

  // we use state here so we don't get the flashes of loading when react-query retries on window focus
  const [error, setError] = useState<ApiClientError | null>(null)

  const { isLoading } = useQuery(['sessionInfo'], getSessionInfo, {
    retry: (failureCount: number, error) =>
      // don't retry on permission denied
      failureCount <= 2 && error.statusCode !== 401,
    staleTime: 60 * 60 * 1000, // 1 hour
    onError: (err: ApiClientError) => {
      if (err.statusCode !== 401) {
        // if it's a real error, show warning
        addNotification('requestError', {
          severity: 'error',
        })
      }
      setError(err)
    },
    onSuccess: () => {
      setError(null)
    },
  })

  if (error) {
    if (error.statusCode === 401) {
      return (
        <Layout>
          <LoginScreen />
        </Layout>
      )
    }

    return (
      <Layout stretch>
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <Stack spacing={[2]}>
            <Typography>getMovies seems to currently be offline</Typography>
            <a
              href="https://t.me/wigger"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button>Send a message to let me know</Button>
            </a>
          </Stack>
        </Box>
      </Layout>
    )
  }

  if (isLoading) {
    return (
      <Layout stretch>
        <LoadingIndicator />
      </Layout>
    )
  }

  return isValidElement(children) ? children : <>{children}</>
}

export default AuthProvider
