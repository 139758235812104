import { useState, useEffect, useCallback } from 'react'

import { useTheme } from '@mui/material/styles'
import { Snackbar, Alert } from '@mui/material'

import { useStore } from './store'
import { NoticationOptions, actions } from './actions'

export interface NotificationProps extends NoticationOptions {
  message: string
}

const NotificationDisplayer = () => {
  const theme = useTheme()
  const [{ notificationQueue }, dispatch] = useStore()
  const [lastNotification, setLastNotification] =
    useState<NotificationProps | null>(null)
  const [open, setOpen] = useState(false)
  const [goToNextNotification, setGoToNextNotification] = useState(true)

  const handleClose =
    (shouldCloseOnClickAway: boolean) => (_event: any, reason: string) => {
      if (!shouldCloseOnClickAway && reason === 'clickaway') {
        return
      }
      setOpen(false)

      setTimeout(
        () => setGoToNextNotification(true),
        theme.transitions.duration.enteringScreen,
      )
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableDispatch = useCallback(dispatch, [])

  const notificationQueueLength = notificationQueue.length
  const firstInQueue = notificationQueue[0]
  useEffect(() => {
    if (notificationQueueLength > 0 && !open && goToNextNotification) {
      setLastNotification(firstInQueue)
      stableDispatch(actions.removeOldestNotification())
      setOpen(true)
      setGoToNextNotification(false)
    }
  }, [
    notificationQueueLength,
    firstInQueue,
    open,
    goToNextNotification,
    stableDispatch,
  ])

  if (!lastNotification) {
    return null
  }

  const { autoHideDuration, message, closeOnClickAway, severity } =
    lastNotification

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose(Boolean(closeOnClickAway))}
    >
      <Alert
        elevation={6}
        variant="filled"
        sx={{ width: '100%' }}
        severity={severity}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}

export default NotificationDisplayer
