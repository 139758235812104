export const types = {
  UPDATE_SEARCH_QUERY: 'UPDATE_SEARCH_QUERY',
  SET_REGISTER_TOKEN: 'SET_REGISTER_TOKEN',
  SEARCH_IMDB: 'SEARCH_IMDB',
  CHANGE_VIEW: 'CHANGE_VIEW',
}

export const actions = {
  updateSearchQuery: (newQuery: string) => {
    return {
      type: types.UPDATE_SEARCH_QUERY,
      payload: { newQuery },
    }
  },
  setRegisterToken: (newToken: string | null) => {
    return {
      type: types.SET_REGISTER_TOKEN,
      payload: { newToken },
    }
  },
  goToHome: () => ({
    type: types.CHANGE_VIEW,
    payload: {
      newView: 'MOVIELIST',
    },
  }),
  searchImdb: () => ({
    type: types.CHANGE_VIEW,
    payload: {
      newView: 'IMDB_SEARCH',
    },
  }),
  changeView: (view: any) => ({
    type: types.CHANGE_VIEW,
    payload: {
      newView: view,
    },
  }),
}
