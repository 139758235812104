import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const queryClient = new QueryClient()

const QueryClientProviderComp = ({ children, withDevtools }: {children: JSX.Element, withDevtools: boolean}) => (
  <QueryClientProvider client={queryClient}>
    {children}
    {withDevtools && <ReactQueryDevtools initialIsOpen={false} />}
  </QueryClientProvider>
)

export default QueryClientProviderComp
